<ng-container *ngIf="!zoneLoaded; else adContent">
  <div class="ad-loader"></div>
</ng-container>

<ng-template #adContent>
  <div #mobileChild>
    <div
      *ngIf="component && isBrowser() && (showDesktop || showMobile)"
      class="ad-layer"
    >
      <div
        *ngIf="showDesktop"
        class="desktop ad-container"
        [innerHtml]="desktopUrl"
      ></div>
      <div
        *ngIf="showMobile"
        class="mobile ad-container"
        [innerHtml]="mobileUrl"
      ></div>
    </div>
  </div>
</ng-template>
