<div class="lead-container" *ngIf="_article">
  <div class="lead">
    <p *ngIf="_article.lead" [innerHTML]="lead"></p>
    <p *ngIf="_article.shortDescription" [innerHTML]="shortDescription"></p>
  </div>
</div>
<div *ngIf="isBrowser()">
  <div *ngFor="let component of _components" [ngSwitch]="component.__component">
    <app-article-body
      *ngSwitchCase="componentTypes.ArticleBody"
      [article]="_article"
    ></app-article-body>
    <app-article-sources
      *ngSwitchCase="componentTypes.Sources"
      [component]="component"
      [article]="_article"
    >
    </app-article-sources>
    <app-social-media
      [component]="component"
      *ngSwitchCase="componentTypes.SocialMedia"
    ></app-social-media>
    <app-custom-html
      *ngSwitchCase="componentTypes.CustomHtml"
      [component]="component"
    ></app-custom-html>
  </div>
  <app-article-author-info-list
    *ngIf="_article"
    [authors]="_article.authors"
  ></app-article-author-info-list>
</div>
