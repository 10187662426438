import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdComponent } from '@app/ad/ad.component';
import { AdSideComponent } from './ad-side/ad-side.component';
import { AdMidComponent } from './ad-mid/ad-mid.component';

@NgModule({
  declarations: [AdComponent, AdSideComponent, AdMidComponent],
  imports: [CommonModule],
  exports: [AdComponent, AdSideComponent, AdMidComponent],
})
export class AdModule {}
